* {
  margin: 0;
  padding: 0;
  list-style: none;

  --success: rgb(18, 143, 18);
  --info: lightblue;
  --warning: orange;
  --danger: red;
}

html,
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: black;
  background-color: white;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
}

.header {
  background-color: #b66a47;
  color: rgb(255, 255, 0);
  font-size: xx-large;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  word-spacing: 0.2rem;
  text-align: center;
  padding: 2rem 3rem;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 2rem;

  .settings {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;

    .form-container {
      margin: 1rem 2rem;

      .num-players-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        .num-players-input {
          max-width: 25%;
        }
      }

      .player-from {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 0.5rem;
        
        .player-name {
          width: 15rem;
        }

        .rmdp-container {
          margin: 0 0.4rem;
          .rmdp-input {
            font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto,
              Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
              "Helvetica Neue", Helvetica, Arial, sans-serif;
            margin: 0;
            height: 2.5rem !important;
            border-color: #dbdbdb;
            border-radius: 4px;
            color: #ea0034;
            font-weight: 700;
            padding-bottom: calc(0.5em - 1px);
            padding-left: calc(0.75em - 1px);
            padding-right: calc(0.75em - 1px);
            padding-top: calc(0.5em - 1px);
          }
          .rmdp-input:hover {
            border-color: #b5b5b5;
          }
        }
      }
    }
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    
    .button {
      margin: 0.1rem 1rem;
    }
  }
  
  .result {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 98%;

    .result-container {
      margin: 1rem 0.5rem;
      min-width: 50%;
      max-width: 100%;
      overflow-x: auto;
    }

  }
}

.form-title {
  font-size: larger;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c2c2c2;
  font-size: 1rem;
  font-weight: 500;
}

.rmdp-panel {
  width: 9rem;
}


tr td:first-child, tr th:first-child {
  border-right: 2px solid #dbdbdb !important;
}
th, td {
  text-align: center !important;
}

.fa-inline {
  margin-right: 0.4rem;
}